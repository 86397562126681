<script>
export default {
  name: 'Gallery',
  page: {
    title: 'Gallery'
  }
}
</script>

<template>
  <div class="gallery">
    <img class="photo" src="img/photo1.jpg"/>
    <img class="photo" src="img/photo2.jpeg"/>
  </div>
</template>

<style lang="scss" scoped>
@use './../assets/scss/abstracts' as *;

.gallery {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}

.photo {
  max-width: 100%;
}
</style>
